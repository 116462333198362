export const minsToHoursAndMins = (minutes: number) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = Math.floor(minutes % 60);

    if (hours === 0) {
        return `${remainingMinutes} min`;
    } else if (remainingMinutes === 0) {
        return `${hours} hr`;
    } else {
        return `${hours} hr ${remainingMinutes} min`;
    }
}

/**
 * Converts epoch timestamps to date strings
 * @param {Number} epochMillis The epoch timestamp
 */
export const epochToDateString = (epochMillis: number): string => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const options: any = {
        hour: "2-digit", minute: "2-digit", timeZoneName: "short",
        day: "2-digit", month: "short", year: "numeric",
    }
    return new Date(epochMillis).toLocaleString(undefined, options)
}



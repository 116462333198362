import { ColorScheme, ColorSchemeProvider, MantineProvider, MantineThemeOverride } from '@mantine/core';
import React, { useMemo, useState } from "react";

const componentsOverride: MantineThemeOverride["components"] = {
    Input: {
        styles: (theme, params, { variant }) => ({
            wrapper: {
                flex: 1
            }
        }),
    },
    Autocomplete: {
        styles: (theme, params, { variant }) => ({
            root: {
                flex: 1
            }
        }),
    },
    TextInput: {
        styles: (theme, params, { variant }) => ({
            root: {
                flex: 1
            }
        }),
    },
}

// https://v6.mantine.dev/theming/mantine-provider/
export const ThemeProvider = (props: { children: React.ReactNode }) => {
    const [colorScheme, setColorScheme] = useState<ColorScheme>('dark');
    const toggleColorScheme = (value?: ColorScheme) =>
        setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));

    const theme: MantineThemeOverride = useMemo(() => ({
        components: componentsOverride,
        colorScheme
    }), [colorScheme])

    return (
        <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
            <MantineProvider theme={theme} withNormalizeCSS withGlobalStyles withCSSVariables>
                {props.children}
            </MantineProvider>
        </ColorSchemeProvider>
    )
}
import { Image, Text, Tooltip, Mark } from '@mantine/core';
import classNames from 'classnames';
import { memo, ReactNode, useMemo } from 'react';
import { FaBiking, FaBus, FaCarSide, FaTrain, FaWalking } from "react-icons/fa";
import { IoMdInformationCircle, IoMdWarning } from "react-icons/io";
import { getPreferredModeOfTransport } from '../storage/localStorage';
import { PublicTransportationMode, Route, RouteLeg, TransportationMode } from '../types/geo';
import { Nullable } from '../types/utils';
import { minsToHoursAndMins } from '../utils/datetime';
import { metersToMiles } from '../utils/geo';
import { roundToSigFigs } from '../utils/numbers';
import { ErrorMessageText } from './ErrorText';
import { EmbeddedMap } from './maps/EmbeddedMap';
import "./RouteRenderer.css"
import { useSmallScreen } from './ScreenSizeContext';
interface RouteRendererProps {
    route?: Route
    expanded?: boolean
}

export const RouteRenderer = memo((props: RouteRendererProps) => {
    const { route, expanded } = props
    const smallScreen = useSmallScreen()

    const consolidatedLegs = useMemo(() => {
        const accumulator: Array<RouteLeg> = []
        route?.route?.legs.forEach(leg => {
            const latestEntry = accumulator.at(accumulator.length - 1)
            if (latestEntry &&
                latestEntry.modeOfTransport === leg.modeOfTransport &&
                latestEntry.modeOfTransport !== TransportationMode.PUBLIC_TRANSPORTATION) {
                latestEntry.distanceInMeters = (latestEntry.distanceInMeters ?? 0) + (leg.distanceInMeters ?? 0)
                latestEntry.durationInMillis = (latestEntry.durationInMillis ?? 0) + (leg.durationInMillis ?? 0)
            }
            else {
                accumulator.push(leg)
            }
        })
        return accumulator
    }, [route?.route?.legs])

    const totalDurationInMinutes = useMemo(() => {
        const millis = consolidatedLegs.reduce((acc, leg) => (leg.durationInMillis ?? 0) + acc, 0) ?? 0
        const minutes = (millis / 1000) / 60
        return minutes
    }, [consolidatedLegs])

    const totalDistanceInMiles = useMemo(() => {
        const meters = consolidatedLegs.reduce((acc, leg) => (leg.distanceInMeters ?? 0) + acc, 0)
        return roundToSigFigs(metersToMiles(meters ?? 0), 3)
    }, [consolidatedLegs])

    const longestLeg = useMemo(() => [...consolidatedLegs].sort((a, b) => (b.durationInMillis ?? 0) - (a.durationInMillis ?? 0)).at(0), [consolidatedLegs])

    if (!route) return null
    if (!route.route) return <ErrorMessageText message='No route found' />
    return (
        <div>
            <Text className='respect-whitespace pt-2'>
                <div>
                    Total:   <strong><Mark>{minsToHoursAndMins(totalDurationInMinutes)}</Mark></strong> <Text c="dimmed" size="sm>" span>({totalDistanceInMiles}mi)</Text>
                </div>
                <div>
                    Longest part:   <LegSummary leg={longestLeg} />
                </div>
            </Text>
            {expanded &&
                <div className={classNames({
                    "col gap-4 pt-4": smallScreen,
                    "row pt-4 !items-start": !smallScreen,
                })}>
                    <EmbeddedMap rounded width={smallScreen ? "100%" : "400px"} height={smallScreen ? "300px" : "400px"} origin={route.origin} destination={route.destination} modeOfTransport={getPreferredModeOfTransport()} />
                    <RouteSummary legs={consolidatedLegs} routeActual={route} />
                </div>
            }
        </div >
    )
})

const RouteSummary = memo((props: { legs: RouteLeg[], routeActual: Route }) => {
    const { legs, routeActual: route } = props
    const smallScreen = useSmallScreen()

    return (
        <div className={classNames({
            "col gap-4": smallScreen,
            "col gap-4 p-4 pt-0": !smallScreen,
        })}>
            <div className="col route-summary-list rounded">
                {legs.map((x, i) => <div><LegSummary key={i} leg={x} /></div>)}
            </div>
            {!smallScreen &&
                <div className='row'>
                    {route.route?.copyright && <Tooltip label={route.route.copyright} withArrow><span><IoMdInformationCircle /></span></Tooltip>}
                    {route.route?.warnings && <Tooltip label={route.route.warnings} withArrow><span><IoMdWarning /></span></Tooltip>}
                </div>
            }
            {smallScreen &&
                <Text c="dimmed" size={"xs"}>
                    {route.route?.warnings}
                    {route.route?.copyright}
                </Text>
            }
        </div>
    )
})

const LegSummary = memo((props: { leg?: RouteLeg }) => {
    const { leg } = props
    const durationInMinutes = useMemo(() => {
        const minutes = ((leg?.durationInMillis ?? 0) / 1000) / 60
        return minutes
    }, [leg?.durationInMillis])

    const distanceInMiles = useMemo(() => {
        return roundToSigFigs(metersToMiles((leg?.distanceInMeters) ?? 0), 3)
    }, [leg?.distanceInMeters])


    return (
        <Text span>
            <LegIcon leg={leg} /> <strong>{minsToHoursAndMins(durationInMinutes)}</strong> <Text c="dimmed" size="sm" span>({distanceInMiles}mi)</Text>
        </Text>
    )
})

const LegIcon = memo((props: { leg?: RouteLeg }) => {
    const { leg } = props

    let image: Nullable<ReactNode> = null
    if (leg?.modeOfTransport === TransportationMode.BIKING) image = <FaBiking />
    else if (leg?.modeOfTransport === TransportationMode.DRIVING) image = <FaCarSide />
    else if (leg?.modeOfTransport === TransportationMode.WALKING) image = <FaWalking />
    else if (leg?.modeOfTransport === TransportationMode.PUBLIC_TRANSPORTATION) {
        if (leg.transitDetails?.line?.vehicle === PublicTransportationMode.BUS) image = <FaBus />
        else image = <FaTrain />
    }

    if (leg?.transitDetails?.line.icon) {
        image = (
            <Image
                className='inline-image'
                height={20}
                width={20}
                src={leg?.transitDetails?.line.icon}
                withPlaceholder
                placeholder={<FaTrain />}
                fit="contain" />
        )
    }

    if (leg?.modeOfTransport === TransportationMode.PUBLIC_TRANSPORTATION) {
        image = <Tooltip label={leg.transitDetails?.line.shortName} withArrow>{image}</Tooltip>
    }

    return image
})

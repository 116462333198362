import { isInDevMode, prettyLog } from "./misc"

/**
 * This method should be the main way errors are tracked in the app 
 * It logs the error in the device logs and (optionally) in Crashlytics
 * @param {Error} error The error object to log 
 * @param {boolean} includeCrashlytics Whether or not to perform Crashlytics logging too. DEFAULT: true
 * @param {string} extraLoggingInfo Extra info that will be logged with the Crashlytics report(if enabled) and with console.log
 */
export const logError = (error: unknown, includeCrashlytics?: boolean, extraLoggingInfo?: string): void => {
    //Just to get a clean stack to know what called logError (not useful in prod due to bundling and minimization)...
    if (isInDevMode()) {
        prettyLog(error, { backgroundColor: "lightpink", textColor: "black" })
        if (extraLoggingInfo) prettyLog(extraLoggingInfo, { backgroundColor: "lightpink", textColor: "black" })
        console.log((new Error()).stack)
    }

    // if (!!includeCrashlytics) {
    //     if (extraLoggingInfo) { crashlytics().log(extraLoggingInfo) }
    //     crashlytics().recordError(error)
    // }
}

export const NotImplementedError = (): Error => {
    return new Error("Not Implemented Yet.")
} 

import React, { memo } from 'react';
import { useBooleanState } from '../utils/hooks';
import { LoadingIndicator } from './LoadingIndicator';

export const IframeContainer = memo((props: React.IframeHTMLAttributes<HTMLIFrameElement>) => {
    const [isLoading, , setLoadingFalse] = useBooleanState(true)

    return (
        <>
            {isLoading &&
                <div style={{ height: props.height, width: props.width, alignItems: "center", justifyContent: "center", display: "flex" }}>
                    <LoadingIndicator shouldShow={isLoading} size={20} />
                </div>}
            <iframe
                title='Dummy'
                {...props}
                onLoad={setLoadingFalse}
                style={{ display: isLoading ? 'none' : 'block' }}
            />
        </>
    );
});

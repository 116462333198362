import { ActionIcon, useMantineColorScheme } from '@mantine/core';
import { memo } from 'react';
import { FiMoon, FiSun } from "react-icons/fi";

export const ColorModeSwitcher = memo((props: { className?: string }) => {
    const { colorScheme, toggleColorScheme } = useMantineColorScheme();
    const dark = colorScheme === 'dark';

    return (
        <ActionIcon
            className={props.className}
            variant="outline"
            color={dark ? 'yellow' : 'blue'}
            onClick={() => toggleColorScheme()}
            title="Toggle color scheme"
        >
            {dark ? <FiSun /> : <FiMoon />}
        </ActionIcon>
    );
})
import { memo } from "react"
import { getCurrentGeoMaster } from "../../geomasters/geomasterGetter"
import { EmbeddedMapProps } from "./types"

const EmbeddedMapImpl = (props: EmbeddedMapProps) => {
    const MapComponent = getCurrentGeoMaster().getMapIframe()
    return <MapComponent {...props} />
}

export enum MAPS_RENDER_MODE {
    USER_COORDS,
    ORIGIN,
    DESTINATION,
    DIRECTIONS
}

export const getMapRenderMode = (props: EmbeddedMapProps) => {
    if (!props.origin && !props.destination) return MAPS_RENDER_MODE.USER_COORDS
    if (!props.origin && props.destination) return MAPS_RENDER_MODE.DESTINATION
    if (props.origin && !props.destination) return MAPS_RENDER_MODE.ORIGIN
    return MAPS_RENDER_MODE.DIRECTIONS
}

export const EmbeddedMap = memo(EmbeddedMapImpl)
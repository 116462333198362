import { Text } from '@mantine/core';

/**
 * Standard error text. Defaults to null if the message is falsey (ie "", null or undefined)
 */

interface ErrorMessageTextProps {
    message?: string
}

export const ErrorMessageText = (props: ErrorMessageTextProps) => {
    if (!props.message) return null

    return (
        <Text c="red" fs="italic" size={"sm"}>
            {props.message}
        </Text>
    )
}

import { Autocomplete, Divider, SelectItemProps, Text, CloseButton } from '@mantine/core';
import React, { forwardRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { GeoMasterName } from '../geomasters/geomaster';
import { getCurrentGeoMaster } from '../geomasters/geomasterGetter';
import { Coordinates, GeographicPlace, PartialGeographicPlace } from '../types/geo';
import { Undefined } from '../types/utils';
import { logError } from '../utils/errors';
import { getUserCoordinates, metersToMiles } from '../utils/geo';
import { noop } from '../utils/misc';
import { isTimedPromiseTimeoutError, MEDIUM_TIMEOUT, timedPromise } from '../utils/promises';
import { LoadingIndicator } from './LoadingIndicator';
import "./PlaceAutocompleteTextInput.css";


interface AutocompleteOption extends PartialGeographicPlace, SelectItemProps {
    value: string
    isAttestation?: boolean
    disabled?: boolean
}

interface PlacesAutocompleteTextInputProps {
    onLocationChosen: (s: GeographicPlace) => void
    onTextChange?: (s: string) => void
    searchBarPlaceholder?: string
    errorMessage?: string,
    initialTextValue?: string
}

interface PlacesAutocompleteTextInputState {
    textInput: string,
    userCoordinates: Coordinates | null
    autocompleteData: AutocompleteOption[],
    errorMessage: string
    isLoadingFromAutocomplete: boolean
    isLoadingFromDetailsRequest: boolean
}

export default class PlacesAutocompleteText extends React.PureComponent<PlacesAutocompleteTextInputProps, PlacesAutocompleteTextInputState>{

    geoMaster = getCurrentGeoMaster()
    currentAutocompleteInvocationId: ReturnType<typeof setTimeout> | null = null

    state: PlacesAutocompleteTextInputState = {
        textInput: this.props.initialTextValue || "",
        userCoordinates: null,
        autocompleteData: [],
        errorMessage: "",
        isLoadingFromAutocomplete: false,
        isLoadingFromDetailsRequest: false
    }

    componentDidMount(): void {
        this.getUserCoordinates()
    }

    getUserCoordinates = (): void => {
        const successCallback = (pos: Coordinates) => {
            this.setState({
                userCoordinates: {
                    latitude: pos.latitude,
                    longitude: pos.longitude
                }
            })
        }
        getUserCoordinates(successCallback, noop)
    }


    render(): React.ReactNode {
        return (
            <div className='col flex-grow'>
                <div className='row w100'>
                    <Autocomplete
                        data={this.state.autocompleteData}
                        itemComponent={AutoCompleteItemComponent}
                        onChange={this.onSearchBarValueChange}
                        value={this.state.textInput}
                        placeholder={this.props.searchBarPlaceholder}
                        error={this.state.errorMessage}
                        onItemSubmit={this.autosuggestListElementOnClick}
                        nothingFound={<Text>Nothing to see here yet.</Text>}
                        rightSection={this.renderRightSection()}
                        filter={this.noFilter}
                        limit={10}
                        onKeyDown={this.getPlaceDetailsOnSubmit}
                    />
                </div>
            </div>
        )
    }

    noFilter = () => true

    //Note: the two functions below area very similar! There should be a way to coalesce
    autosuggestListElementOnClick = async (place: AutocompleteOption): Promise<void> => {
        try {
            this.setState({ isLoadingFromDetailsRequest: true })
            const location = await this.geoMaster.getPlaceForAutocompleteUsingPartial(place)
            this.setState({ autocompleteData: [] })
            this.setSearchBarValue(location.name)
            this.clearErrorFeedback()
            this.props.onLocationChosen(location)
        } catch (e) {
            this.provideErrorFeedback(e)
            logError(e)
        } finally {
            this.setState({ isLoadingFromDetailsRequest: false })
        }
    }

    getPlaceDetailsOnSubmit = async (e: React.KeyboardEvent<HTMLInputElement>): Promise<void> => {
        try {
            if (e.key !== "Enter" || e.target.hasAttribute("aria-activedescendant")) return
            e.target.blur()
            this.setState({ isLoadingFromDetailsRequest: true })
            const location = await this.geoMaster.getPlaceForAutocompleteUsingQuery(this.state.textInput, this.state.userCoordinates)
            if (location) {
                this.setState({ autocompleteData: [] })
                this.setSearchBarValue(location.name)
                this.clearErrorFeedback()
                this.props.onLocationChosen(location)
            } else {
                this.provideErrorFeedback("Couldn't find a place!")
            }
        } catch (e) {
            this.provideErrorFeedback(e)
            logError(e)
        } finally {
            this.setState({ isLoadingFromDetailsRequest: false })
        }
    }

    renderRightSection = (): React.ReactElement | null => {
        const isLoading = this.state.isLoadingFromAutocomplete || this.state.isLoadingFromDetailsRequest
        if (!isLoading) {
            return <CloseButton onClick={this.clearInput} />
        } else {
            return <LoadingIndicator shouldShow size={20} />
        }
    }

    clearInput = () => {
        this.setState({ autocompleteData: [] })
        this.setSearchBarValue("")
    }

    onSearchBarValueChange = (newValue: string): void => {
        this.setSearchBarValue(newValue)
        //This is essentially a no-op when the user's coordinates haven't been gotten
        if (newValue.trim().length >= 3) { this.getAutoCompleteThrottled() }
    }

    //Cancels a previously queued version of itself, and fetches a
    //Autocomplete suggestion after DELAY seconds (to prevent excessive fetches when the user
    //is typing fast)
    getAutoCompleteThrottled = (): void => {
        if (this.currentAutocompleteInvocationId) {
            clearTimeout(this.currentAutocompleteInvocationId)
        }

        const DELAY_IN_MS = 300
        this.currentAutocompleteInvocationId = setTimeout(
            () => {
                this.setState({ isLoadingFromAutocomplete: true })
                this.clearErrorFeedback()
                timedPromise((async (): Promise<PartialGeographicPlace[] | null> => {
                    const predictions = await this.geoMaster.getAutocompleteFromQuery(
                        this.state.textInput,
                        this.state.userCoordinates
                    )
                    return predictions
                })(), MEDIUM_TIMEOUT)
                    .then(pred => {
                        const mappedPredictions: Undefined<AutocompleteOption[]> = pred?.map(x => ({ ...x, label: x.name, value: x.geoMasterProvidedId ?? uuidv4() }))
                        if (mappedPredictions) {
                            mappedPredictions.push({ label: "ATTESTATION", value: "ATTESTATION", isAttestation: true, geoMasterName: GeoMasterName._DUMMY_, disabled: true })
                            this.setState({ autocompleteData: mappedPredictions })
                        }
                    })
                    .catch(e => {
                        logError(e)
                        this.provideErrorFeedback(e)
                    })
                    .finally(() => {
                        this.currentAutocompleteInvocationId = null
                        this.setState({ isLoadingFromAutocomplete: false })
                    })
            }, DELAY_IN_MS
        )
    }

    clearErrorFeedback = (): void => {
        this.setState({ errorMessage: "" })
    }

    provideErrorFeedback = (e: any): void => {
        let message = ""
        if (typeof e === "string") { message = e }
        if (isTimedPromiseTimeoutError(e)) { message = "Timeout!" }
        if (e.message) { message = e.message }
        this.setState({ errorMessage: message })
    }

    setSearchBarValue = (val: string): void => {
        this.setState({ textInput: val })
        this.props.onTextChange && this.props.onTextChange(val)
    }
}


const AutoCompleteItemComponent = forwardRef<HTMLDivElement, AutocompleteOption>(
    ({ name, distanceToUsersInMeters, subtext, geoMasterProvidedId, geoMasterName, isAttestation, ...others }: AutocompleteOption, ref) => (
        <div ref={ref} {...others}>
            {isAttestation ? (
                getCurrentGeoMaster().getAttestationForAutocomplete()
            ) : (
                <>
                    <Text className='autosuggest-title'>
                        {name}
                    </Text>
                    <Text className='autosuggest-subtitle'>
                        {distanceToUsersInMeters && `(${metersToMiles(distanceToUsersInMeters).toFixed(2)}mi) - `}
                    </Text>
                    <Text className='autosuggest-distance'>
                        {subtext}
                    </Text>
                    <Divider />
                </>
            )}
        </div>
    )
);

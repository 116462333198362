import { getCurrentGeoMaster } from "../geomasters/geomasterGetter";
import { Query, SerializedQuery } from "../locationQueries/locationQueries";
import { QueryDeserializer } from "../locationQueries/locationQueryDeserializer";
import { TransportationMode } from "../types/geo";

const QUERIES_STORAGE_KEY = "savesQueriesV1"

export const getSavedQueries = (): Query[] => {
    const serializedQueriesRaw = localStorage.getItem(QUERIES_STORAGE_KEY);
    if (!serializedQueriesRaw) return [];
    const serializedQueries = JSON.parse(serializedQueriesRaw) as SerializedQuery[];
    const queries: Query[] = []
    for (const serializedQuery of serializedQueries) {
        if (QueryDeserializer.isOpenQuery(serializedQuery)) queries.push(QueryDeserializer.deserializeOpenQuery(serializedQuery))
        else if (QueryDeserializer.isAddressQuery(serializedQuery)) queries.push(QueryDeserializer.deserializeAddressQuery(serializedQuery))
    }
    return queries
}

export const setSavedQueries = (queries: Query[]) => {
    const serializedQueries = queries.map(x => x.serialize())
    localStorage.setItem(QUERIES_STORAGE_KEY, JSON.stringify(serializedQueries));
}


const PREFERRED_MODE_OF_TRANSPORT_KEY = "modeOfTransportV1"

export const getPreferredModeOfTransport = (): TransportationMode => {
    const defaultValue = TransportationMode.DRIVING
    const storedValue = localStorage.getItem(PREFERRED_MODE_OF_TRANSPORT_KEY) as TransportationMode
    if (!storedValue || !getCurrentGeoMaster().supportedModesOfTransport.includes(storedValue)) {
        return defaultValue //TODO: tell the user about this
    } else {
        return storedValue
    }
}

export const setPreferredModeOfTransport = (mode: TransportationMode) => {
    localStorage.setItem(PREFERRED_MODE_OF_TRANSPORT_KEY, mode);
}

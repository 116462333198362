import React, { useEffect, useRef, useState, ReactNode } from 'react';
import { Nullable } from '../types/utils';
import { createContext, useContextSelector } from 'use-context-selector';

const ScreenSizeContext = createContext<boolean>(false);

function ScreenSizeContextWrapper(props: { children: ReactNode }) {
  const resizeObserverRef = useRef<Nullable<ResizeObserver>>(null);
  const elementRef = useRef<HTMLDivElement>(null)
  const [isSmallScreen, setIsSmallScreen] = useState(false)

  useEffect(() => {
    resizeObserverRef.current = new ResizeObserver((entries) => {
      if (!entries.length) {
        return;
      }

      const { width } = entries[0].contentRect;
      setIsSmallScreen(width < 650)
    });

    if (elementRef.current) {
      resizeObserverRef.current.observe(elementRef.current);
    }

    return () => {
      resizeObserverRef.current?.disconnect();
    };
  }, []);

  return (
    <div
      ref={elementRef}
      style={{
        width: "100vw",
        height: "100vh"
      }}
    >
      <ScreenSizeContext.Provider value={isSmallScreen}>
        {props.children}
      </ScreenSizeContext.Provider>
    </div>
  );
}

export default ScreenSizeContextWrapper;

export const useSmallScreen = () => {
  const val = useContextSelector(ScreenSizeContext, c => c);
  return val
}

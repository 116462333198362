import { Undefined } from "../types/utils";

export const noop = () => null

//https://create-react-app.dev/docs/adding-custom-environment-variables/
export const isInDevMode = (): boolean => {
    return process.env.NODE_ENV === 'development';
}

/**
 * A way to console.log with some nice formatting options
 * @param {*} msg The message
 * @param {*} style {textColor, backgroundColor, fontSize}
 */
interface prettyLogArgs {
    textColor?: string
    backgroundColor?: string
    fontSize?: number
}
export const prettyLog = (msg: any, style?: prettyLogArgs): void => {
    let styleString = ""
    if (style?.textColor) { styleString += `color: ${style.textColor};` }
    if (style?.backgroundColor) { styleString += `background: ${style.backgroundColor};` }
    if (style?.fontSize) { styleString += `font-size: ${style.fontSize}px;` }
    console.log('%c%s', styleString, msg)
}


/**
 * Standard alert to show when something's not ready for use yet (or is broken due to new developments)
 */
export const ShowNotSupportedAlert = (): void => {
    alert(
        "Not yet, young whippersnapper! This ins't done yet",
    )
}


/**
 * Returns an set of keys that are in A but not in B
 * @param objA Object A
 * @param objB Object B
 */
export function objectDifference(objA: Record<string, unknown>, objB: Record<string, unknown>): Set<string> {
    const setA = new Set(Object.keys(objA))
    const setB = new Set(Object.keys(objB))
    return new Set([...setA].filter(x => !setB.has(x)))
}


export const assertDefined = <T>(value: Undefined<T>): T => {
    if (value === undefined) throw new Error("Unexpected undefined value!")
    return value
}
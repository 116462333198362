/**
 * Mapping APIs are tricky, expensive, and have areas where they work better than others.
 * That calls for being able to effortlessly swap and mix the APIs we use depending on things like
 * user preferences, user location, the type of user account, cost restraints, etc.
 * Geomasters are the only entry point to mapping APIs, and we [plan to] have many different Geomasters
 * to facilitate different combinations/usage patterns of APIs.
 */

import { EmbeddedMapProps } from "../components/maps/types";
import { Coordinates, GeographicPlace, PartialGeographicPlace, Route, TransportationMode } from "../types/geo";
import { Nullable, Optional } from "../types/utils";

export abstract class GeoMaster {
    abstract init(): Promise<void>

    abstract readonly supportedModesOfTransport: TransportationMode[]

    abstract getAutocompleteFromQuery(query: string, reference: Optional<Coordinates>): Promise<PartialGeographicPlace[]>
    abstract getPlaceForAutocompleteUsingPartial(partialPlace: PartialGeographicPlace): Promise<GeographicPlace>
    abstract getPlaceForAutocompleteUsingQuery(placeQuery: string, reference: Optional<Coordinates>): Promise<Nullable<GeographicPlace>>
    abstract getAttestationForAutocomplete(): React.ReactElement

    abstract getPlaceFromOpenQuery(query: string, reference: Optional<Coordinates>): Promise<Nullable<GeographicPlace>>

    abstract getDirectionsBetweenPlaces(origin: GeographicPlace, destination: GeographicPlace): Promise<Route>

    abstract getMapIframe(): React.MemoExoticComponent<(props: EmbeddedMapProps) => JSX.Element>

}

// All available geomasters
export enum GeoMasterName {
    _DUMMY_ = "dummy", // For things like dummy data
    ALFRED = "alfred"
}
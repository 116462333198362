import React from "react";
import { logError } from "../utils/errors";

interface State {
    hasError: boolean
}

interface Props {
    children: React.ReactNode
}

export class ErrorBoundary extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: any) {
        return { hasError: true };
    }

    componentDidCatch(error: unknown, errorInfo: React.ErrorInfo) {
        logError(error)
    }

    render() {
        if (this.state.hasError) {
            return (<p>Oops! Looks like something went wrong. We'll look into it.</p>)
        }

        return this.props.children;
    }
}
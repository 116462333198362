import { ActionIcon, CloseButton, Input, Space, TextInput, Tooltip } from '@mantine/core';
import { ChangeEvent, memo, useCallback } from "react";
import { FiLayout, FiMaximize2, FiMinimize2, FiTrash } from "react-icons/fi";
import { AddressSpecificQuery, OpenQuery, Query } from "../locationQueries/locationQueries";
import { GeographicPlace } from "../types/geo";
import { Undefined } from '../types/utils';
import { useToggleState } from "../utils/hooks";
import PlacesAutocompleteText from "./PlaceAutocompleteTextInput";
import { RouteRenderer } from "./RouteRenderer";
import { useSmallScreen } from './ScreenSizeContext';

interface QueryComponentProps {
    query: Query;
    onQueryChanged: (key: string) => void;
    onQueryRemoved: (key: string) => void;
    onQueryChosenForSidepanel: (key: string) => void;
}

export const QueryListElement = memo((props: QueryComponentProps) => {
    const { query } = props;
    const smallScreen = useSmallScreen();

    const onLocationChosenAddress = useCallback((location: GeographicPlace) => {
        (query as AddressSpecificQuery).location = location;
        props.onQueryChanged(query.key);
    }, [props, query]);

    const onQueryChangeOpen = useCallback((text: Undefined<string>) => {
        (query as OpenQuery).textQuery = text;
        props.onQueryChanged(query.key);
    }, [props, query]);

    let input = undefined;
    if (query instanceof AddressSpecificQuery) {
        input = <AddressSpecificQueryInput onChange={onLocationChosenAddress} initialValue={query.location?.name} />
    } else {
        input = <OpenQueryInput onChange={onQueryChangeOpen} query={query} />
    }

    const onDelete = useCallback(() => {
        props.onQueryRemoved(query.key);
    }, [props, query]);

    const onChosenForSidepanel = useCallback(() => {
        props.onQueryChosenForSidepanel(query.key);
    }, [props, query]);

    const [expanded, toggleExpansion] = useToggleState(false);

    return (
        <div>
            <div className="row w100 !items-start">
                {input}
                <Space w="sm" />
                <span className='button-panel'>
                    <Tooltip label={"Delete"} withArrow>
                        <ActionIcon onClick={onDelete} color="red" variant="light"><FiTrash /></ActionIcon>
                    </Tooltip>
                    {(query.route && !smallScreen) && <Tooltip label={"Link to Sidepanel"} withArrow>
                        <ActionIcon onClick={onChosenForSidepanel} color="primary" variant="light"><FiLayout /></ActionIcon>
                    </Tooltip>}
                    {query.route && <Tooltip label={expanded ? "Shrink" : "Expand"} withArrow>
                        <ActionIcon onClick={toggleExpansion} color="primary" variant="light">{expanded ? <FiMinimize2 /> : <FiMaximize2 />}</ActionIcon>
                    </Tooltip>}
                </span>
            </div>
            <RouteRenderer route={query.route} expanded={expanded} />
        </div>
    );
});


const OpenQueryInput = (props: {
    query: OpenQuery,
    onChange: (val: Undefined<string>) => void
}) => {
    const onChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        props.onChange(event.target.value);
    }, [props]);

    const clear = useCallback(() => {
        props.onChange("");
    }, [props]);

    return (
        <TextInput
            onChange={onChange}
            value={props.query.textQuery}
            rightSection={<CloseButton onClick={clear} />}
            description={props.query.route?.destination?.longAddress ?? props.query.route?.destination?.name}
            inputWrapperOrder={['label', 'error', 'input', 'description']} />
    )
}

const AddressSpecificQueryInput = (props: {
    initialValue: Undefined<string>,
    onChange: (location: GeographicPlace) => void
}) => {
    return (
        <PlacesAutocompleteText onLocationChosen={props.onChange} initialTextValue={props.initialValue} />
    )
}


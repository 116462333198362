/**
 * Wraps a promise in a timeout using promise.race
 * If the promise is timed out, it rejects and returns 'Timed out'
 * @param {Promise} promise The promise to time
 * @param {Number} ms The timeout in ms
 */
export const timedPromise = <T>(promise: Promise<T>, ms: number): Promise<T> => {

    // Create a promise that rejects in <ms> milliseconds
    //Its okay to say this resolves to T because it never really resolves
    const timeout = new Promise<T>((_, reject) => {
        setTimeout(() => reject({
            name: "timeout",
            message: `Something timed out after ${ms} milliseconds`,
        }),
            ms)
    })

    // Returns a race between our timeout and the passed in promise
    return Promise.race<Promise<T>>([
        promise,
        timeout,
    ])
}

export const isTimedPromiseTimeoutError = (e: any): boolean => {
    if (e?.name === "timeout" && e?.message) { return true }
    return false
}

export const sleep = (ms: number): Promise<void> => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const SHORT_TIMEOUT = 7000
export const MEDIUM_TIMEOUT = 10000
export const LONG_TIMEOUT = 15000

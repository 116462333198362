import { momentum } from 'ldrs';
import { memo } from 'react';
import { useMantineColorScheme } from '@mantine/core';

momentum.register()

export const LoadingIndicator = memo((props: { size?: number, shouldShow: boolean }) => {
    const { colorScheme } = useMantineColorScheme();
    const color = colorScheme === 'dark' ? 'white' : 'black'

    if (!props.shouldShow) return null

    return (
        <l-momentum
            size={props.size ?? 35}
            speed="1.25"
            color={color}
        />
    )
})

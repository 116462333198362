import { Text } from '@mantine/core';
import haversine from 'haversine-distance';
import { initGoogleApi, mapGoogleModeOfTransport, mapGooglePublicTransportationMode, requestAutocompleteFromGoogle, requestCompletePlaceDataFromGoogle, requestCompletePlaceDataFromGoogleFromQuery, requestDirectionsBetweenPlaces } from "../apis/GooglePlacesApi";
import { OSMResult, reverseGeocodeFromNominatim, reverseGeocodeFromPhoton } from "../apis/OpenStreetMapsApi";
import { AlfredMapIframe } from "../components/maps/AlfredMapIframe";
import { Coordinates, GeographicPlace, PartialGeographicPlace, Route, RouteDirections, RouteLeg, TransportationMode } from "../types/geo";
import { Nullable, Optional } from "../types/utils";
import { SHORT_TIMEOUT, timedPromise } from "../utils/promises";
import { GeoMaster, GeoMasterName } from "./geomaster";

/******************************************************
   ___      _        __                      _   
  /   \    | |      / _|    _ _    ___    __| |  
  | - |    | |     |  _|   | '_|  / -_)  / _` |  
  |_|_|   _|_|_   _|_|_   _|_|_   \___|  \__,_|  
_|"""""|_|"""""|_|"""""|_|"""""|_|"""""|_|"""""| 
"`-0-0-'"`-0-0-'"`-0-0-'"`-0-0-'"`-0-0-'"`-0-0-' 
******************************************************/

export class Alfred extends GeoMaster {
    static geoMasterName = GeoMasterName.ALFRED

    async init(): Promise<void> {
        await initGoogleApi()
    }

    async getAutocompleteFromQuery(query: string, reference?: Optional<Coordinates>): Promise<PartialGeographicPlace[]> {
        const predictions = await requestAutocompleteFromGoogle(query, reference)
        return predictions.map(x => ({
            geoMasterName: Alfred.geoMasterName,
            geoMasterProvidedId: x.place_id,
            name: x.structured_formatting.main_text,
            distanceToUsersInMeters: x.distance_meters,
            subtext: x.structured_formatting.secondary_text
        }))
    }

    async getPlaceForAutocompleteUsingQuery(query: string, reference: Optional<Coordinates>): Promise<Nullable<GeographicPlace>> {
        const place = await this.getPlaceFromOpenQuery(query, reference)
        if (place) {
            const OSMLocation = await this.reverseGeocodeToOSM(place.coords)
            return ({
                geoMasterName: Alfred.geoMasterName,
                timestampInMillis: Date.now(),
                name: OSMLocation.name ?? "Unnamed",
                coords: place.coords,
                geoMasterProvidedId: place.geoMasterProvidedId
            })
        } else {
            return null
        }
    }

    async getPlaceForAutocompleteUsingPartial(partialPlace: PartialGeographicPlace): Promise<GeographicPlace> {
        if (partialPlace.geoMasterName !== Alfred.geoMasterName || !partialPlace.geoMasterProvidedId) {
            throw new Error("Alfred received invalid data!")
        }

        //Getting the place's coordinates from Google
        const placeDetails = await requestCompletePlaceDataFromGoogle(partialPlace.geoMasterProvidedId)
        const geo = placeDetails?.geometry?.location
        if (!geo) {
            throw new Error("We couldn't get the coordinates of this place. Sorry!")
        }

        //Due to Google's restrictive TOS (we can't store their stuff on our servers), 
        //its better to reverse geocode those coordinates using more free OSM solutions
        const googleCoordinates: Coordinates = { latitude: geo.lat(), longitude: geo.lng() }
        const OSMLocation = await this.reverseGeocodeToOSM(googleCoordinates)
        return ({
            geoMasterName: Alfred.geoMasterName,
            timestampInMillis: Date.now(),
            name: OSMLocation.name ?? "Unnamed",
            coords: { latitude: geo.lat(), longitude: geo.lng() },
            geoMasterProvidedId: placeDetails.place_id
        })
    }

    getAttestationForAutocomplete(): React.ReactElement {
        return (<Text>Powered by Google</Text>)
    }

    async getPlaceFromOpenQuery(query: string, reference: Optional<Coordinates>): Promise<Nullable<GeographicPlace>> {
        const result = await requestCompletePlaceDataFromGoogleFromQuery(query, reference)
        const place = result?.at(0)
        if (!place) return null
        const geo = place?.geometry?.location
        if (!geo) {
            throw new Error("We couldn't get the coordinates of this place. Sorry!")
        }

        return ({
            geoMasterName: Alfred.geoMasterName,
            timestampInMillis: Date.now(),
            name: place.name ?? "Unnamed",
            coords: { latitude: geo.lat(), longitude: geo.lng() },
            geoMasterProvidedId: place.place_id,
            longAddress: place.formatted_address
        })
    }

    async getDirectionsBetweenPlaces(origin: GeographicPlace, destination: GeographicPlace): Promise<Route> {
        if (origin.geoMasterName !== Alfred.geoMasterName || destination.geoMasterName !== Alfred.geoMasterName) {
            throw new Error("Alfred received invalid data!")
        }

        const route: Route = { origin, destination, route: null }
        const receivedRoutes = await requestDirectionsBetweenPlaces(origin.coords, destination.coords, destination.geoMasterProvidedId)
        const receivedRoute = receivedRoutes?.routes.at(0)
        if (!receivedRoute) return route

        const constructedRoute: RouteDirections = {
            legs: [],
            copyright: receivedRoute.copyrights,
            warnings: receivedRoute.warnings
        }

        receivedRoute.legs.forEach(leg => {
            this.recursivelyConstructSteps(leg.steps, constructedRoute.legs)
        });

        route.route = constructedRoute
        return route
    }

    getMapIframe(): React.MemoExoticComponent<any> {
        return AlfredMapIframe
    }

    readonly supportedModesOfTransport: TransportationMode[] = [
        TransportationMode.BIKING,
        TransportationMode.DRIVING,
        TransportationMode.PUBLIC_TRANSPORTATION,
        TransportationMode.WALKING
    ]

    /**
     * Uses a combination of OSM-based APIs to reverse geocode a location. 
     * Internally uses timedPromise, so it can timeout
     * @param coords 
     */
    private reverseGeocodeToOSM = async (coords: Coordinates): Promise<OSMResult> => {
        const responses = await Promise.allSettled([
            timedPromise(reverseGeocodeFromNominatim(coords), SHORT_TIMEOUT),
            timedPromise(reverseGeocodeFromPhoton(coords), SHORT_TIMEOUT),
        ])

        //Processing to be able to pick the best OSM suggestion
        const sortedSuggestions = responses
            .filter(pr => pr.status === "fulfilled")
            .map(pr => (pr as PromiseFulfilledResult<OSMResult>).value)
            .sort((a, b) => haversine(coords, a.coords) - haversine(coords, b.coords))

        if (sortedSuggestions.length === 0) {
            throw new Error("We couldn't get the coordinates of this place. Sorry!")
        }
        return sortedSuggestions[0]
    }

    private recursivelyConstructSteps(steps: google.maps.DirectionsStep[], accumulator: RouteLeg[]) {
        steps.forEach(step => {
            if (!step.steps?.length) {
                accumulator.push({
                    distanceInMeters: step.distance?.value,
                    durationInMillis: step.duration?.value ? step.duration.value * 1000 : undefined,
                    modeOfTransport: mapGoogleModeOfTransport(step.travel_mode),
                    transitDetails: step.transit && {
                        line: {
                            icon: step.transit.line.icon,
                            shortName: step.transit.line.short_name,
                            color: step.transit.line.color,
                            vehicle: mapGooglePublicTransportationMode(step.transit.line.vehicle.type)
                        },
                        startStation: {
                            name: step.transit.departure_stop.name
                        },
                        endStation: {
                            name: step.transit.arrival_stop.name
                        },
                        numberOfStops: step.transit.num_stops,
                    }
                })
            } else {
                this.recursivelyConstructSteps(step.steps ?? [], accumulator)
            }
        })
    }
}
import { v4 as uuid } from "uuid";
import { getCurrentGeoMaster } from '../geomasters/geomasterGetter';
import { Coordinates, GeographicPlace, Route } from "../types/geo";
import { assertDefined } from "../utils/misc";

export enum SerializedQueryType {
    OPEN = "open",
    ADDRESS_SPECIFIC = "address_specific"
}

interface SerializedQueryBase {
    type: SerializedQueryType
    queryBaseVersion: 1
}

abstract class QueryBase {
    readonly key: string
    route?: Route

    constructor() {
        this.key = uuid()
    }

    abstract getDirections(reference: GeographicPlace): Promise<void>
    abstract serialize(): SerializedQueryBase

    reset(): void {
        this.route = undefined
    }
}

export interface SerializedAddressSpecificQuery extends SerializedQueryBase {
    type: SerializedQueryType.ADDRESS_SPECIFIC,
    location?: GeographicPlace
    addressQueryVersion: 1
}

export class AddressSpecificQuery extends QueryBase {
    public location?: GeographicPlace

    constructor(serializedQuery?: SerializedAddressSpecificQuery) {
        super()
        this.location = serializedQuery?.location
    }

    async getDirections(reference: GeographicPlace): Promise<void> {
        if (!this.location) return
        const geoMaster = getCurrentGeoMaster()
        this.route = await geoMaster.getDirectionsBetweenPlaces(reference, this.location)
    }

    serialize(): SerializedAddressSpecificQuery {
        return {
            type: SerializedQueryType.ADDRESS_SPECIFIC,
            location: this.location,
            queryBaseVersion: 1,
            addressQueryVersion: 1
        }
    }
}

export interface SerializedOpenQuery extends SerializedQueryBase {
    type: SerializedQueryType.OPEN,
    openQueryVersion: 1
    textQuery?: string
}

export class OpenQuery extends QueryBase {
    public textQuery?: string


    constructor(serializedQuery?: SerializedOpenQuery) {
        super()
        this.textQuery = serializedQuery?.textQuery
    }


    convertToCoords(x: google.maps.places.PlaceResult): Coordinates {
        return {
            latitude: assertDefined(x.geometry?.location?.lat()),
            longitude: assertDefined(x.geometry?.location?.lng())
        }
    }

    async getDirections(reference: GeographicPlace): Promise<void> {
        const geoMaster = getCurrentGeoMaster()
        const trimmedQuery = this.textQuery?.trim()
        if (!trimmedQuery) return
        const location = await geoMaster.getPlaceFromOpenQuery(trimmedQuery, reference.coords)
        if (!location) return //TODO: do something better here
        this.route = await geoMaster.getDirectionsBetweenPlaces(reference, location)
    }

    serialize(): SerializedOpenQuery {
        return {
            type: SerializedQueryType.OPEN,
            textQuery: this.textQuery,
            queryBaseVersion: 1,
            openQueryVersion: 1
        }
    }
}

export type Query = OpenQuery | AddressSpecificQuery
export type SerializedQuery = SerializedOpenQuery | SerializedAddressSpecificQuery
